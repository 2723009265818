import { useCallback, useMemo, useState, type FC } from 'react'

import {
  AllCommunityModule,
  ColDef,
  ModuleRegistry,
  GridApi,
} from 'ag-grid-community'
import { MasterDetailModule } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'

import { routes } from '@redwoodjs/router'

import FilterNavTabs, {
  NavTabs,
} from 'src/components/FilterNavTabs/FilterNavTabs'

import { SearchField } from '../Library/SearchField'

import ResourceAccessManagementModal from './ResourceAccessManagementModal'
import {
  ACCESS_MANAGEMENT_TAB,
  type BaserowWorkspace,
  type Membership,
  type ResourceGridRow,
} from './types'
import {
  generateUserGridRows,
  generateWorkspaceGridRows,
  getColumnDefs,
  getDetailCellRendererParams,
} from './utils'

interface AccessManagementGridProps {
  memberships: Membership[]
  baserowWorkspaces: BaserowWorkspace[]
  selectedTab: ACCESS_MANAGEMENT_TAB
}

const AccessManagementGrid: FC<AccessManagementGridProps> = ({
  memberships,
  baserowWorkspaces,
  selectedTab,
}) => {
  const PAGE_BASE_PATH = routes.accessManagementTab({ tab: '' })

  const navigationOptions: NavTabs[] = [
    { key: ACCESS_MANAGEMENT_TAB.USERS, title: 'users' },
    { key: ACCESS_MANAGEMENT_TAB.WORKSPACES, title: 'workspaces' },
  ]

  const [activeTab, setActiveTab] = useState<NavTabs>(
    navigationOptions.find((tab) => tab.key === selectedTab),
  )

  const selectedTabIndex = useMemo(() => {
    return navigationOptions.findIndex((tab) => tab.key === activeTab.key)
  }, [activeTab])

  const handleTabClick = (newTab: ACCESS_MANAGEMENT_TAB) => {
    const newActiveTab = navigationOptions.find((tab) => tab.key === newTab)
    setActiveTab(newActiveTab)
    window.history.pushState({}, '', PAGE_BASE_PATH + newActiveTab.title)
  }

  const [resourceGridRow, setResourceGridRow] = useState<ResourceGridRow>(null)

  const [
    resourceAccessManagementModalOpen,
    setResourceAccessManagementModalOpen,
  ] = useState<boolean>(false)

  ModuleRegistry.registerModules([MasterDetailModule, AllCommunityModule])

  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const onGridReady = useCallback((params: { api: GridApi }) => {
    setGridApi(params.api)
  }, [])

  const onQuickFilterInput = (text: string) => {
    if (!gridApi) return
    gridApi.setGridOption('quickFilterText', text)
  }

  const userGridRows = useMemo(() => {
    return generateUserGridRows(memberships, baserowWorkspaces)
  }, [memberships, baserowWorkspaces])

  const gridRows = useMemo(() => {
    if (activeTab.key === ACCESS_MANAGEMENT_TAB.USERS) {
      return userGridRows
    } else {
      return generateWorkspaceGridRows(baserowWorkspaces)
    }
  }, [activeTab, baserowWorkspaces, userGridRows])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      sortable: true,
      filter: true,
      flex: 1,
    }
  }, [])

  const columnDefs = useMemo(() => {
    return getColumnDefs(
      activeTab.key,
      setResourceAccessManagementModalOpen,
      setResourceGridRow,
    )
  }, [activeTab, setResourceAccessManagementModalOpen, setResourceGridRow])

  const detailCellRendererParams = useMemo(() => {
    return getDetailCellRendererParams(
      activeTab.key,
      setResourceAccessManagementModalOpen,
      setResourceGridRow,
    )
  }, [activeTab])

  return (
    <div className="flex h-full flex-col gap-2 p-2">
      <FilterNavTabs
        navTabs={navigationOptions}
        selectedTab={selectedTabIndex}
        onTabClickCallBack={handleTabClick}
      />
      <div className="h-full p-2">
        <SearchField
          value={''}
          onChange={onQuickFilterInput}
          placeholder={`Search ${activeTab.title}`}
          className="mb-2"
        />
        <AgGridReact
          onGridReady={onGridReady}
          masterDetail={true}
          detailRowAutoHeight={true}
          columnDefs={columnDefs}
          rowData={gridRows}
          defaultColDef={defaultColDef}
          detailCellRendererParams={detailCellRendererParams}
          suppressCellFocus={true} // stop the cell highlighting behaviour
          suppressRowTransform={true} // the master detail can get a little blurry without this
        />
      </div>
      <ResourceAccessManagementModal
        isOpen={resourceAccessManagementModalOpen}
        onClose={() => setResourceAccessManagementModalOpen(false)}
        userGridRows={userGridRows}
        resourceGridRow={resourceGridRow}
      />
    </div>
  )
}

export default AccessManagementGrid
