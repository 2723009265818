import type { FC } from 'react'

import type { ICellRendererParams } from 'ag-grid-enterprise'

import BaseBadge from '../BasesScreen/BaseBadge'

import type { BaseGridRow, UserBaseGridRow } from './types'

const BaseNameCellRenderer: FC<
  ICellRendererParams<BaseGridRow | UserBaseGridRow>
> = ({ data }) => {
  if (!data) return null

  return (
    <div className="flex items-center space-x-2">
      <BaseBadge
        baseId={data.scopeId}
        baseName={data.scopeName}
        iconColor={data.iconColor}
        selectedIcon={data.selectedIcon}
        size="small"
      />
      <span>{data.scopeName}</span>
    </div>
  )
}

export default BaseNameCellRenderer
