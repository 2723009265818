import React, { useState, type FC } from 'react'

import { useMutation } from '@apollo/client'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { captureException } from '@sentry/browser'
import type { ICellRendererParams } from 'ag-grid-enterprise'
import { isProductionFn } from 'api/src/common/environment'
import type { AssignRole, AssignRoleVariables } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import AccessInheritanceDisclaimer from './AccessInheritanceDisclaimer'
import {
  BaserowRoleTypes,
  type BaserowRole,
  type ResourceAccessManagementModalGridRow,
  type UserResourceGridRow,
} from './types'

const baserowRoles: BaserowRole[] = [
  {
    value: BaserowRoleTypes.ADMIN,
    label: 'Admin',
    description: 'Can fully configure and edit workspaces and applications.',
  },
  {
    value: BaserowRoleTypes.BUILDER,
    label: 'Builder',
    description: 'Can fully configure and edit applications.',
  },
  {
    value: BaserowRoleTypes.EDITOR,
    label: 'Editor',
    description:
      'Can fully edit data but not configure applications or workspaces.',
  },
  {
    value: BaserowRoleTypes.COMMENTER,
    label: 'Commenter',
    description: 'Can only read and comment on data.',
  },
  {
    value: BaserowRoleTypes.VIEWER,
    label: 'Viewer',
    description: 'Can only read data.',
  },
  {
    value: BaserowRoleTypes.NO_ROLE_LOW_PRIORITY,
    label: 'No role',
    description: 'Get default workspace role from their teams.',
  },
]

const selectableRoles = (() => {
  if (isProductionFn()) {
    // ADMIN role not shown as we don't want to allow users to assign ADMIN roles
    return baserowRoles.filter((role) => role.value !== BaserowRoleTypes.ADMIN)
  }
  // In development show all roles so we can toggle between them
  return baserowRoles
})()

export const ASSIGN_ROLE = gql`
  mutation AssignRole($input: AssignRoleInput!) {
    assignBaserowRole(input: $input) {
      role
    }
  }
`

export const BaserowRoleSelect: FC<
  ICellRendererParams<
    UserResourceGridRow | ResourceAccessManagementModalGridRow
  >
> = ({ data }) => {
  const [selectedRole, setSelectedRole] = useState<string>(
    baserowRoles.find((role) => role.value === data.access)?.value ||
      BaserowRoleTypes.NO_ROLE_LOW_PRIORITY,
  )

  const [roleSaving, setRoleSaving] = useState<boolean>(false)

  const [assignRole] = useMutation<AssignRole, AssignRoleVariables>(
    ASSIGN_ROLE,
    {
      onCompleted: () => {
        setRoleSaving(false)
        toast.success('Role assigned')
      },
      onError: (error) => {
        setRoleSaving(false)
        captureException(error)
        setSelectedRole(data.access)

        toast.error('Failed to assign role')
      },
    },
  )

  const handleChange = (event: SelectChangeEvent) => {
    setRoleSaving(true)
    setSelectedRole(event.target.value)
    assignRole({
      variables: {
        input: {
          workspaceId: data.workspaceId,
          role: event.target.value,
          scopeId: data.scopeId,
          scopeType: data.scopeType,
          subjectType: data.subjectType,
          subjectEmail: data.subjectEmail,
        },
      },
    })
  }

  return (
    <div className="h-full">
      <Select
        value={selectedRole}
        onChange={handleChange}
        renderValue={(selected: string) => {
          const role = baserowRoles.find((r) => r.value === selected)
          if (!role) return ''

          return (
            <Typography variant="body2" sx={{ minWidth: '6rem' }}>
              {roleSaving ? <LoadingSpinner size="x-small" /> : role.label}
            </Typography>
          )
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            paddingY: 0,
            paddingX: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {selectableRoles.map((role) => (
          <MenuItem key={role.value} value={role.value}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box display="flex" alignItems="center" gap={0.5}>
                <Typography variant="body2" fontWeight="bold">
                  {role.label}
                </Typography>
              </Box>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {role.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
        {data.scopeType === 'application' && <AccessInheritanceDisclaimer />}
      </Select>
    </div>
  )
}
