import type { ColDef, ICellRendererParams } from 'ag-grid-enterprise'

import AccessWithInheritanceHeader from './AccessWithInheritanceHeader'
import BaseNameCellRenderer from './BaseNameCellRenderer'
import { BaserowRoleSelect } from './BaserowRoleSelect'
import ManageResourceAccessButton from './ManageResourceAccessButton'
import {
  BaserowWorkspace,
  Membership,
  ACCESS_MANAGEMENT_TAB,
  type ResourceGridRow,
  type UserGridRow,
  type WorkspaceGridRow,
  type UserWorkspaceGridRow,
  type UserBaseGridRow,
  type BaseGridRow,
  type ResourceAccessManagementModalGridRow,
  type DetailCellRendererParams,
  type Subject,
} from './types'
import UserAvatarCellRenderer from './UserAvatarCellRenderer'

export const generateUserGridRows = (
  memberships: Membership[],
  baserowWorkspaces: BaserowWorkspace[],
) => {
  const userGridRows = memberships.map((membership) => {
    const subject: Subject = {
      subjectId: membership.user.baserowUserId,
      subjectEmail: membership.user.email,
      subjectName: membership.user.name,
      subjectType: 'auth.User',
    }
    const userGridRow: UserGridRow = {
      ...subject,
      avatarUrl: membership.user.avatarUrl,
      workspaces: baserowWorkspaces.map((workspace) => {
        const userWorkspaceGridRow: UserWorkspaceGridRow = {
          ...subject,
          scopeId: workspace.baserowWorkspaceId,
          scopeName: workspace.workspaceName,
          scopeType: 'workspace',
          workspaceId: workspace.baserowWorkspaceId,
          access: workspace.roleAssignments.find(
            (roleAssignment) =>
              roleAssignment.subjectId === membership.user.baserowUserId,
          )?.role,
          bases: workspace.applications.map((application) => {
            const userBaseGridRow: UserBaseGridRow = {
              ...subject,
              scopeId: application.id,
              scopeName: application.name,
              scopeType: 'application',
              workspaceId: workspace.baserowWorkspaceId,
              access: application?.roleAssignments?.find(
                (roleAssignment) =>
                  roleAssignment.subjectId === membership.user.baserowUserId,
              )?.role,
              selectedIcon: application.baserowCardAppearance?.icon,
              iconColor: application.baserowCardAppearance?.colorName,
            }
            return userBaseGridRow
          }),
        }
        return userWorkspaceGridRow
      }),
    }
    return userGridRow
  })
  return userGridRows
}

export const generateWorkspaceGridRows = (
  baserowWorkspaces: BaserowWorkspace[],
) => {
  const workspaceGridRows = baserowWorkspaces.map((workspace) => {
    const workspaceGridRow: WorkspaceGridRow = {
      scopeId: workspace.baserowWorkspaceId,
      scopeName: workspace.workspaceName,
      scopeType: 'workspace',
      workspaceId: workspace.baserowWorkspaceId,
      bases: workspace.applications.map((application) => {
        const baseGridRow: BaseGridRow = {
          scopeId: application.id,
          scopeName: application.name,
          scopeType: 'application',
          workspaceId: workspace.baserowWorkspaceId,
          selectedIcon: application.baserowCardAppearance?.icon,
          iconColor: application.baserowCardAppearance?.colorName,
        }
        return baseGridRow
      }),
    }
    return workspaceGridRow
  })
  return workspaceGridRows
}

export const getColumnDefs = (
  tabKey: string,
  setResourceAccessManagementModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setResourceGridRow: React.Dispatch<React.SetStateAction<ResourceGridRow>>,
) => {
  if (tabKey === ACCESS_MANAGEMENT_TAB.USERS) {
    const colDefs: ColDef[] = [
      {
        cellRenderer: 'agGroupCellRenderer',
        flex: 0,
        width: 40,
        maxWidth: 50,
        resizable: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: 'User',
        field: 'subjectName',
        cellRenderer: UserAvatarCellRenderer,
        filter: false,
      },
      {
        headerName: 'Email',
        field: 'subjectEmail',
        filter: false,
      },
    ]

    return colDefs
  }

  const colDefs: ColDef[] = [
    {
      headerName: 'Workspace',
      field: 'scopeName',
      cellRenderer: 'agGroupCellRenderer',
      filter: false,
    },
    {
      headerName: 'Actions',
      colId: 'actions',
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params: ICellRendererParams) => (
        <ManageResourceAccessButton
          cellParams={params}
          setResourceAccessManagementModalOpen={
            setResourceAccessManagementModalOpen
          }
          setResourceGridRow={setResourceGridRow}
        />
      ),
    },
  ]
  return colDefs
}

export const getResourceAccessManagementModalColumnDefs = (
  scopeType: string,
) => {
  if (!scopeType) return []

  const colDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'subjectName',
      cellRenderer: UserAvatarCellRenderer,
      filter: true,
    },
    {
      headerName: 'Email',
      field: 'subjectEmail',
      filter: true,
    },
    {
      headerName: 'Access',
      field: 'access',
      cellRenderer: BaserowRoleSelect,
      filter: true,
      headerComponent:
        scopeType === 'application' && AccessWithInheritanceHeader,
    },
  ]

  return colDefs
}

export const getDetailCellRendererParams = (
  tabKey: string,
  setResourceAccessManagementModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setResourceGridRow: React.Dispatch<React.SetStateAction<ResourceGridRow>>,
) => {
  if (tabKey === ACCESS_MANAGEMENT_TAB.USERS) {
    const detailCellRendererParams: DetailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'Workspace',
            field: 'scopeName',
            cellRenderer: 'agGroupCellRenderer',
            filter: true,
          },
          {
            headerName: 'Access',
            field: 'access',
            cellRenderer: BaserowRoleSelect,
            filter: true,
          },
        ],
        defaultColDef: {
          flex: 1,
        },
        masterDetail: true,
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: [
              {
                field: 'scopeName',
                headerName: 'Base',
                cellRenderer: BaseNameCellRenderer,
              },
              {
                field: 'access',
                headerName: 'Access',
                cellRenderer: BaserowRoleSelect,
                headerComponent: AccessWithInheritanceHeader,
              },
            ],
            defaultColDef: {
              flex: 1,
            },
          },
          getDetailRowData: (params) => {
            params.successCallback(params.data.bases)
          },
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.workspaces)
      },
    }
    return detailCellRendererParams
  } else {
    const detailCellRendererParams: DetailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'scopeName',
            headerName: 'Base',
            cellRenderer: BaseNameCellRenderer,
          },
          {
            colId: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            filter: false,
            cellRenderer: (params: ICellRendererParams<ResourceGridRow>) => (
              <ManageResourceAccessButton
                cellParams={params}
                setResourceAccessManagementModalOpen={
                  setResourceAccessManagementModalOpen
                }
                setResourceGridRow={setResourceGridRow}
              />
            ),
            cellStyle: () => ({
              border: 'none', // Suppresses focus styling
            }),
          },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.bases)
      },
    }
    return detailCellRendererParams
  }
}

export const generateResourceAccessManagementModalGridRows = (
  userGridRows: UserGridRow[],
  resourceGridRow: ResourceGridRow,
) => {
  if (!resourceGridRow || !userGridRows) return []
  const resourceAccessManagementModalGridRows = userGridRows.map((user) => {
    const access = (() => {
      if (resourceGridRow.scopeType === 'workspace') {
        return user.workspaces.find(
          (workspace) => workspace.scopeId === resourceGridRow.scopeId,
        )?.access
      } else {
        const workspace = user.workspaces.find((workspace) =>
          workspace.bases.find(
            (base) => base.scopeId === resourceGridRow.scopeId,
          ),
        )

        return workspace?.bases.find(
          (base) => base.scopeId === resourceGridRow.scopeId,
        )?.access
      }
    })()

    const resourceAccessManagementModalGridRow: ResourceAccessManagementModalGridRow =
      {
        subjectId: user.subjectId,
        subjectEmail: user.subjectEmail,
        subjectName: user.subjectName,
        subjectType: user.subjectType,
        scopeId: resourceGridRow.scopeId,
        scopeName: resourceGridRow.scopeName,
        scopeType: resourceGridRow.scopeType,
        workspaceId: resourceGridRow.workspaceId,
        access,
      }
    return resourceAccessManagementModalGridRow
  })
  return resourceAccessManagementModalGridRows
}
