import type { FC } from 'react'

import { Avatar } from '@mui/material'
import type { ICellRendererParams } from 'ag-grid-enterprise'

import type { UserGridRow } from './types'

const UserAvatarCellRenderer: FC<ICellRendererParams<UserGridRow>> = ({
  data,
}) => {
  if (!data) return null

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        src={data.avatarUrl}
        alt={data.subjectName ?? ''}
        style={{ width: 28, height: 28, marginRight: 8 }}
      />
      <span>{data.subjectName}</span>
    </div>
  )
}

export default UserAvatarCellRenderer
