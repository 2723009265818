import type { IDetailCellRendererParams } from 'ag-grid-enterprise'
import type { FindAccessManagementQuery } from 'types/graphql'

export type Membership = FindAccessManagementQuery['memberships'][0]
export type BaserowWorkspace = FindAccessManagementQuery['baserowWorkspaces'][0]

export enum ACCESS_MANAGEMENT_TAB {
  USERS = 'USERS',
  WORKSPACES = 'WORKSPACES',
}

export type BaserowRole = {
  value: string
  label: string
  description: string
}

export enum BaserowRoleTypes {
  ADMIN = 'ADMIN',
  BUILDER = 'BUILDER',
  EDITOR = 'EDITOR',
  COMMENTER = 'COMMENTER',
  VIEWER = 'VIEWER',
  NO_ROLE_LOW_PRIORITY = 'NO_ROLE_LOW_PRIORITY',
}

export interface Subject {
  subjectId: number
  subjectType: string
  subjectEmail: string
  subjectName: string
}

export interface ScopedResource {
  scopeId: number
  scopeName: string
  scopeType: string
  workspaceId: number
}

interface RoleAssignment {
  access: string
}

export type SubjectWithAccess = Subject & RoleAssignment

export type BaseGridRow = ScopedResource & {
  selectedIcon: string
  iconColor: string
}

export type WorkspaceGridRow = ScopedResource & {
  bases: BaseGridRow[]
}

export type ResourceGridRow = BaseGridRow | WorkspaceGridRow

export type UserBaseGridRow = SubjectWithAccess & BaseGridRow

export type UserWorkspaceGridRow = SubjectWithAccess &
  Omit<WorkspaceGridRow, 'bases'> & {
    bases: UserBaseGridRow[]
  }

export type UserGridRow = Subject & {
  avatarUrl: string
  workspaces: UserWorkspaceGridRow[]
}

export type UserResourceGridRow = UserBaseGridRow | UserWorkspaceGridRow

export type ResourceAccessManagementModalGridRow = SubjectWithAccess &
  ScopedResource

export type DetailCellRendererParams = Pick<
  IDetailCellRendererParams,
  'detailGridOptions' | 'getDetailRowData'
>
