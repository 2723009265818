import type { FC } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import type { routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import AccessManagementCell from 'src/components/AccessManagement/AccessManagementCell'
import { ACCESS_MANAGEMENT_TAB } from 'src/components/AccessManagement/types'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import { useAuth } from 'src/Providers'

export type PageQueryTypes = Partial<
  Parameters<typeof routes.accessManagementTab>[0]
>

const AccessManagementPage: FC<PageQueryTypes> = (props) => {
  const tabToUpper = (props.tab ?? '').toUpperCase()
  const selectedTab =
    ACCESS_MANAGEMENT_TAB[tabToUpper] ?? ACCESS_MANAGEMENT_TAB.USERS

  const { currentUser } = useAuth()

  return (
    <>
      <Metadata title="Access Management" description="AccessManagement page" />
      <PageHeader
        title="Access Management"
        parentDataTestId="access-management-page-title"
      />
      {currentUser.userData.email === 'regan@stafflink.com.au' ? (
        // TODO Regan (remove conditional): temporary measure -> allow regan to live test the feature
        <AccessManagementCell selectedTab={selectedTab} />
      ) : (
        <FeatureToggle
          feature={FLAG_FEATURES.BASEROW_ACCESS_MANAGEMENT}
          InactiveComponent={<FeatureDenied />}
        >
          <AccessManagementCell selectedTab={selectedTab} />
        </FeatureToggle>
      )}
    </>
  )
}

export default AccessManagementPage
