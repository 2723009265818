import React, { FC, useMemo } from 'react'

import type { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'

import Modal from 'src/components/Modal/Modal'

import type {
  ResourceAccessManagementModalGridRow,
  ResourceGridRow,
  UserGridRow,
} from './types'
import {
  generateResourceAccessManagementModalGridRows,
  getResourceAccessManagementModalColumnDefs,
} from './utils'

interface ResourceAccessManagementModalProps {
  isOpen: boolean
  onClose: () => void
  userGridRows: UserGridRow[]
  resourceGridRow: ResourceGridRow
}

const ResourceAccessManagementModal: FC<ResourceAccessManagementModalProps> = ({
  isOpen,
  onClose,
  userGridRows,
  resourceGridRow,
}) => {
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      sortable: true,
      filter: true,
      flex: 1,
    }
  }, [])

  const modalGridRows = useMemo<ResourceAccessManagementModalGridRow[]>(() => {
    return generateResourceAccessManagementModalGridRows(
      userGridRows,
      resourceGridRow,
    )
  }, [userGridRows, resourceGridRow])

  const modalColumnDefs = useMemo<ColDef[]>(() => {
    return getResourceAccessManagementModalColumnDefs(
      resourceGridRow?.scopeType,
    )
  }, [resourceGridRow?.scopeType])

  return (
    <Modal
      open={isOpen}
      title={`Manage Access: ${resourceGridRow?.scopeName}`}
      onClose={onClose}
      onCancel={onClose}
      dialogClassName={'overflow-hidden'}
      className="w-full"
      childClassName={'mt-0'}
    >
      <div className="flex h-[calc(100vh-200px)] w-full flex-col">
        <AgGridReact
          rowData={modalGridRows}
          defaultColDef={defaultColDef}
          columnDefs={modalColumnDefs}
        />
      </div>
    </Modal>
  )
}

export default ResourceAccessManagementModal
