import type { FC } from 'react'

import type {
  FindAccessManagementQuery,
  FindAccessManagementQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import LoadingComponent from 'src/components/Library/Loading'

import AccessManagementGrid from '../AccessManagementGrid'
import type { ACCESS_MANAGEMENT_TAB } from '../types'

export const QUERY = gql`
  query FindAccessManagementQuery {
    memberships: membershipsByClient {
      id
      user {
        id
        name
        email
        avatarUrl
        baserowUserId
      }
    }
    baserowWorkspaces: baserowWorkspaces {
      id
      baserowWorkspaceId
      workspaceName(asBaserowAdmin: true)
      roleAssignments(asBaserowAdmin: true) {
        subjectId
        role
      }
      applications(asBaserowAdmin: true) {
        id
        name
        roleAssignments(asBaserowAdmin: true) {
          subjectId
          role
        }
        baserowCardAppearance {
          colorName
          icon
        }
      }
    }
  }
`

export const Loading = () => (
  <div className="h-[400px]">
    <LoadingComponent />
  </div>
)

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindAccessManagementQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<
  FindAccessManagementQuery,
  FindAccessManagementQueryVariables
> & {
  selectedTab: ACCESS_MANAGEMENT_TAB
}

export const Success: FC<Props> = ({
  memberships,
  baserowWorkspaces,
  selectedTab,
}) => {
  return (
    <AccessManagementGrid
      memberships={memberships}
      baserowWorkspaces={baserowWorkspaces}
      selectedTab={selectedTab}
    />
  )
}
