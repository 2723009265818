import { Tooltip } from '@mui/material'
import { WarningTriangle } from 'iconoir-react'

import { ACCESS_INHERITANCE_DISCLAIMER_TEXT } from './constants'

const AccessWithInheritanceHeader = () => {
  return (
    <div className="flex items-center">
      <span>Access</span>
      <Tooltip title={ACCESS_INHERITANCE_DISCLAIMER_TEXT}>
        <WarningTriangle
          style={{ marginLeft: 5, color: '#FFA500', cursor: 'pointer' }}
        />
      </Tooltip>
    </div>
  )
}

export default AccessWithInheritanceHeader
