import type { FC } from 'react'

import type { ICellRendererParams } from 'ag-grid-enterprise'

import Button from '../Library/Button/Button'

import type { ResourceGridRow } from './types'

interface ManageResourceAccessButtonProps {
  cellParams: ICellRendererParams<ResourceGridRow>
  setResourceAccessManagementModalOpen: (open: boolean) => void
  setResourceGridRow: (resourceGridRow: ResourceGridRow) => void
}

const ManageResourceAccessButton: FC<ManageResourceAccessButtonProps> = ({
  cellParams,
  setResourceAccessManagementModalOpen,
  setResourceGridRow,
}) => {
  const { data } = cellParams
  const handleClick = () => {
    setResourceGridRow(data)

    setResourceAccessManagementModalOpen(true)
  }

  return (
    <Button onClick={handleClick} className="w-40">
      Manage access
    </Button>
  )
}

export default ManageResourceAccessButton
