import type { FC } from 'react'

import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { WarningTriangle } from 'iconoir-react'

import { ACCESS_INHERITANCE_DISCLAIMER_TEXT } from './constants'

const AccessInheritanceDisclaimer: FC = () => {
  return (
    <Box className="m-2 rounded-md border border-yellow-300 bg-yellow-50">
      <Alert
        icon={<WarningTriangle style={{ fontSize: 'small' }} />}
        severity="warning"
        sx={{
          backgroundColor: 'transparent',
          color: '#92400E',
          padding: '4px',
          alignItems: 'center',
        }}
      >
        <AlertTitle
          sx={{ fontWeight: 'bold', color: '#78350F', fontSize: '0.8rem' }}
        >
          Other users might inherit access!
        </AlertTitle>
        <Typography variant="caption">
          {ACCESS_INHERITANCE_DISCLAIMER_TEXT}
        </Typography>
      </Alert>
    </Box>
  )
}

export default AccessInheritanceDisclaimer
